<template>
	<div>
		<vx-card>
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Registrar*</label>
					<v-select class="w-full" v-model="formData['registrar']" multiple :options="formRegistrars" :clearable="false"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">TLD*</label>
					<v-select class="w-full" v-model="formData['tld']" multiple :options="formTLDs" :clearable="false"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Source*</label>
					<v-select class="w-full" v-model="formData['source']" multiple :options="formSources" :clearable="false"></v-select>
				</div>
				<div class="vx-col w-full mb-2">
					<div class="pt-4">
						<vs-switch color="success" v-model="formData['active']">
							<span slot="on">Activate</span>
							<span slot="off">Deactivate</span>
						</vs-switch>
					</div>
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButton" id="button-with-loading" class="mb-2" @click="openConfirm()" :disabled="user_role != 'admin' || (!formInit['done'] || (formData['registrar'] == '' || formData['tld'] == '' || formData['source'] == ''))">Submit</vs-button>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import vSelect from 'vue-select'

	export default{
		components: {
			'v-select': vSelect,
		},
		data() {
			return {
				formData: {
					registrar: [],
					tld: [],
					source: [],
					active: true,
				},
				formInit: {
					done: false,
					registrars: [],
					tlds: [],
					sources: [],
				},
			}
		},
		watch: {
			formTLDs(val) {
				this.formData['tld'] = val;
			}
		},
		computed: {
			formRegistrars: function(){
				let registrarArray = this.formInit['registrars'].map(x => {
					return {
						label: x['organization'],
						value: x['registrar_id']
					}
				});
				
				return registrarArray;
			},
			formTLDs: function(){
				let tldArray = this.formInit['tlds'].map(x => {
					return {
						label: x['tld'],
						value: x['id']
					}
				});
				
				return tldArray;
			},
			formSources: function(){
				let sourcesArray = this.formInit['sources'].map(x => {
					return {
						label: x['name'],
						value: x['id']
					}
				});
				
				return sourcesArray;
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading > .con-vs-loading");
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Create this RTS Rule?',
					accept: this.createRTSRule
				});
			},
			createRTSRule() {
				let self = this;
				// Show loader
				self.showButtonLoading();
				// Post Axios Call
				this.$axiosSecure.post('/rts-rules-create-multi', {
					...self.formData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'RTS rule creation success',
						text: 'The RTS rule has been created'
					});
					
					// Hide loader
					self.hideButtonLoading();
					self.$router.push({ path: '/rts-rule-list' });
				})
				.catch((error) => {
					// Hide loader
					self.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
		},
		mounted() {
			let self = this;
			// Get Registrars JSON
			this.$axiosSecure.get('/get-registrars')
			.then((response) => {
				self.formInit['registrars'] = response.data;
				
				// Get TLD JSON
				self.$axiosSecure.get('/get-tlds')
				.then((response) => {
					self.formInit['tlds'] = response.data;

					// Get Sources JSON
					self.$axiosSecure.get('/get-sources')
					.then((response) => {
						self.formInit['sources'] = response.data;
						// Enable Submit
						self.formInit['done'] = true;
					})
					.catch((error) => {
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});
		}
	}
</script>
